<template>
  <v-card
    elevation="0"
    style="height: 96px; width: 100%; max-width: 340px; border-radius: 8px;"
    @click="handleProdLink(produto.id)"
  >
    <v-row
      class="mx-0 my-0"
    >
      <div
        v-if="(produto.desconto && produto.id_campanha === '-1' && produto.nomeCampanha === '') && ((this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade !== 2) || (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0))"
        style="
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #E40E0E;
        border-bottom-right-radius: 200px;
        border-top-left-radius: 30px;"
      >
        <p style="transform: rotate(-45deg); color: white; font-size: 12px; margin-left: 5px; font-weight: 700;">
          {{ produto.desconto }}% Off
        </p>
      </div>
      <v-col
        cols="4"
        sm="3"
        md="3"
        lg="3"
        xl="3"
      >
        <v-avatar
          tile
          size="70"
          color="white"
        >
          <img
            :src="showImage"
          >
        </v-avatar>
      </v-col>
      <v-col
        cols="8"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <div style="width: 100%; height: 100%; margin-left: 8px">
          <p
            style="color: #143693; font-size: 16px; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 700;"
            class="mt-1 mb-1 pl-0"
            color="primary"
          >
            {{ formatDescText( produto.nome, ( $vuetify.breakpoint.smOnly ? 50 : 34 )) }}
          </p>
          <p
            style="font-size: 16px; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
          >
            <b>{{ produto.marca }}</b> | Mínimo {{ produto.pedido_min }} unidades
          </p>
          <div v-if="produto.desconto && !produto.nome_campanha">
            <span style="text-decoration: line-through; color: #808080; font-size: 14px">
              R$ {{ currencyFormatter( produto.preco ) }}
            </span>
            <span
              style="color: #143693; font-size: 16px;"
              class="font-weight-bold my-0 ml-5"
            >
              R$ {{ currencyFormatter( produto.precoWithDiscount ) }}
            </span>
          </div>
          <div v-else>
            <p
              style="color: #143693; font-size: 16px; font-weight: 800"
              class="font-weight-bold my-0"
            >
              R$ {{ produto.precoWithDiscount ? currencyFormatter( produto.precoWithDiscount ) : currencyFormatter( produto.preco ) }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { formatDescText, formatImageLink } from './helpers'
  import { REMOVE_FROM_CART } from '@/store/modules/carrinho'
  import { mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagens: ['24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png'],
          }
        ),
      },
    },
    data () {
      return {
        descLength: 150,
        currencyFormatter,
        formatDescText,
      }
    },
    computed: {
      showImage () {
        return this.produto.imagens[0] ? this.formatImageLink(this.produto.imagens[0]) : 'img/prodImage.png'
      },
    },
    methods: {
      ...mapActions('carrinho', [REMOVE_FROM_CART]),
      handleProdLink (id) {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/loja` }).catch(() => {})
        this.$router.push({
          path: `/${this.$store.state.user.roles[0].slug}/loja/produto`,
          query: { id },
        }).catch(() => {})
        this.$store.dispatch('produtoIndividual/BOOTSTRAP', { id })
        this.$store.commit('loja/SET_STATE', { campaignDialog: false })
      },
      formatImageLink,
    },
  }
</script>
<style lang="scss" scoped>
.triangulo {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
}
.triangulo::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    background-color: red;
    transform: rotate(-44deg);
    transform-origin: left bottom;
}

img:before {
  content: " ";
  height: calc(100% + 10px);
  background-color: #bebebe;
  width: 100%;
}
</style>
