<template>
  <div>
    <v-col>
      <v-data-iterator
        :items="catalogoFiltered"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row
            class="mt-2 mb-2"
          >
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="mb-6"
            >
              <card-produto
                :produto="item"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row
            class="mb-2"
            align="center"
            justify="center"
          >
            <v-spacer />
            <v-btn
              dark
              color="#183B94"
              large
              style="font-size: 20px;"
              class="text-none ml-1 text-white"
              @click="handleVerMaisClick"
            >
              Ver mais produtos
            </v-btn>
            <v-spacer />
          </v-row>
        </template>
      </v-data-iterator>
    </v-col>
    <campaign-dialog />
  </div>
</template>

<script>
  import CategoriasStore, { BOOTSTRAP } from '@/store/modules/categorias'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import CardProduto from './_cardProduto.vue'
  import CampaignDialog from './_campanhaDialog.vue'

  export default {
    components: {
      CardProduto,
      CampaignDialog,
    },
    data: () => ({
      model: null,
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: 'name',
    }),
    computed: {
      ...mapState('categorias', ['loading', 'categorias']),
      ...mapGetters('loja', ['catalogoFiltered']),
      numberOfPages () {
        return Math.ceil(this.catalogo.length / this.itemsPerPage)
      },
    },
    async created () {
      if (!this.$store.hasModule('categorias')) { this.$store.registerModule('categorias', CategoriasStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('categorias', [BOOTSTRAP]),
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      handleVerMaisClick () {
        this.$router.push({
          path: `/${this.$store.state.user.roles[0].slug}/loja/produtos`,
        })
      },
    },
  }
</script>

<style scoped>
.v-application .primary--text {
    color: #003677 !important;
    caret-color: #003677 !important;
}

.v-card {
  border-radius: 10px;
}
</style>
