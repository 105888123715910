<template>
  <v-row class="px-0">
    <v-col
      v-if="campanhas.length > 0"
      cols="12"
      class="pt-1 pb-1"
    >
      <v-carousel
        hide-delimiter-background
        show-arrows-on-hover
        height="440"
        class="pt-0 pb-0"
        cycle
        interval="5000"
      >
        <v-card
          class="pt-0 pb-0"
          style="margin: 0px; padding: 60px;"
          elevation="0"
        >
          <v-carousel-item
            v-for="( campanha, index) in campanhas"
            :key="`card-${index}`"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <card-campanha-home :campanha="campanha" />
          </v-carousel-item>
        </v-card>
      </v-carousel>
    </v-col>
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import CardCampanhaHome from './_cardCampanhaHome.vue'

  export default {
    name: 'Campanhas',
    components: {
      CardCampanhaHome,
    },
    data () {
      return {
        onboarding: 0,
      }
    },
    computed: {
      ...mapState('loja', ['campanhasAtivas']),
      campanhas () {
        this.campanhasAtivas.forEach(campanha => { if (campanha.tipo === 'oferta') { campanha.id = '-1' } })
        // const campanhas = this.campanhasAtivas.map(campanha => campanha.id !== '-1')
        return this.campanhasAtivas
      },
    },
    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>
<style lang="scss" scoped>

.card-scroll-container {
  overflow: auto
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.v-card {
  border-radius: 10px;
  background: linear-gradient(90deg, #2DADED 0%, #67CCFF 100%);
}

</style>
