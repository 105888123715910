<template>
  <div
    class="content"
    style="margin: 0px 10px;"
  >
    <base-loading v-if="loading" />
    <div v-else-if="( (selecaoFazenda === null || selecaoFazenda === '' || selecaoFazenda === undefined) || ((selecaoProdutor === null || selecaoProdutor === '' || selecaoProdutor === undefined) && this.$store.state.user.roles[0].slug === 'tecnico') )">
      <v-alert
        color="warning"
        icon="mdi-account-cowboy-hat"
        dark
        dismissible
      >
        <span> <h2>{{ this.$store.state.user.roles[0].slug === 'tecnico' ?
          'Por favor selecione um Produtor e uma Fazenda no canto superior direito da loja!' :
          'Por favor selecione uma Fazenda no canto superior direito da loja!' }} </h2></span>
      </v-alert>
    </div>
    <div v-else-if="catalogoFiltered.length === 0 && !loading">
      <v-alert
        color="error"
        icon="mdi-cow"
        dark
        dismissible
      >
        <span> <h2>Não existem produtos cadastrados para a Fazenda selecionada.</h2></span>
      </v-alert>
    </div>
    <div v-else>
      <campanhas />
      <produtos />
    </div>
  </div>
</template>
<script>
  import LojaStore, { BOOTSTRAP } from '@/store/modules/loja'
  import CarrinhoStore from '@/store/modules/carrinho'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Produtos from './Produtos.vue'
  import Campanhas from './Campanhas.vue'
  import BannerBlog from './_bannerBlog.vue'

  export default {
    components: {
      Produtos,
      Campanhas,
      BannerBlog,
    },
    data () {
      return {
        nada: '',
      }
    },
    computed: {
      ...mapState('loja', ['loading', 'catalogo']),
      ...mapState('fazendas', ['selecaoFazenda']),
      ...mapState('produtores', ['selecaoProdutor']),
      ...mapGetters('loja', ['catalogoFiltered']),
    },
    async created () {
      if (!this.$store.hasModule('loja')) { this.$store.registerModule('loja', LojaStore) }
      if (!this.$store.hasModule('carrinho')) { this.$store.registerModule('carrinho', CarrinhoStore) }
      this.BOOTSTRAP()
      const ref = this.$router.currentRoute.path
      this.$store.commit('produtoIndividual/SET_STATE', { ref })
    },
    methods: {
      ...mapActions('loja', [BOOTSTRAP]),
    },
  }
</script>
