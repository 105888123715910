<template>
  <v-row>
    <v-col
      style="display: flex; align-items: center;"
      cols="12"
      md="auto"
    >
      <img
        style="margin: 25px 0px 20px 20px; border-radius: 8px;"
        width="285"
        height="385"
        elevation="3"
        :src="campanha.imagem ? campanha.imagem : require('../../assets/ofertas.png')"
        contain
        @click="$router.push({ path: `/${$store.state.user.roles[0].slug}/loja/produtos/?campanha=${campanha.id}` })"
      >
    </v-col>
    <v-col>
      <v-list-item-content v-if="$vuetify.breakpoint.smAndUp">
        <v-list-item-title
          style="color:white; font-size: 2.0em; font-style: italic; margin-top: 10px;"
          class="mb-1"
        >
          <b>{{ formatDescText( campanha.nome, ( $vuetify.breakpoint.lgAndUp ? 90 : 36 )) }}</b>
        </v-list-item-title>
        <div
          style="color:white; font-size: 1.0em; font-style: italic; align-self: center;"
          class="mb-6"
        >
          Período de vigência: {{ formatData(campanha.data_inicio) }} á {{ formatData(campanha.data_fim) }}
        </div>
        <v-data-iterator
          v-if="$vuetify.breakpoint.smAndUp"
          :items="produtosEmOferta"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          hide-default-footer
          no-data-text="Não há produtos disponíveis para esta Campanha/Oferta!"
        >
          <template v-slot:default="props">
            <v-row style="overflow-y: auto; height: 325px;">
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                lg="6"
                xl="3"
                class="mb-4 py-0"
              >
                <card-campanha
                  class="my-0 py-0 mx-auto"
                  :produto="item"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-list-item-content>
    </v-col>
  </v-row>
</template>

<script>
  import { formatDescText } from './helpers'
  import { mapGetters } from 'vuex'
  import CardCampanha from './_cardCampanha.vue'

  export default {
    components: {
      CardCampanha,
    },
    props: {
      campanha: {
        type: Object,
        default () {
          return {
            nome: 'Page',
            descricao: 'Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
          }
        },
      },
    },
    data: () => ({
      page: 1,
      itemsPerPage: 16,
      sortBy: 'name',
      formatDescText,
    }),
    computed: {
      ...mapGetters('loja', ['catalogoFiltered']),
      produtosEmOferta () {
        return this.catalogoFiltered.filter(({ id_campanha }) =>
          (this.campanha.id === id_campanha),
        )
      },
      numberOfPages () {
        return Math.ceil(this.catalogo.length / this.itemsPerPage)
      },
    },
    methods: {
      formatData (date) {
        if (!date) {
          return ''
        }
        const data = new Date(date)
        data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000)
        return (this.adicionaZero(data.getDate()) + '/' + this.adicionaZero(data.getMonth() + 1) + '/' + data.getFullYear())
      },
      adicionaZero (numero) {
        if (numero <= 9) {
          return '0' + numero
        } else {
          return numero
        }
      },
      handleVerMaisClick () {
        this.$router.push({
          path: `/${this.$store.state.user.roles[0].slug}/loja/produtos`,
        })
      },
    },
  }
</script>
<style lang="scss" scooped>

.v-carousel__controls {
  top: 0;
  right: 0;
  bottom: auto;
  margin: 10px;
  margin-left: -70px !important;
  left: auto;
  position: absolute;
  justify-content: flex-end !important;
  z-index: 1;
}
</style>
